import Link from 'next/link';
import Slider from 'react-slick';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import styles from './carousel.module.css';
import { camelToSlug } from '~/util/stringUtils';

const Card = dynamic(() => import('~/components/resource/card'), { ssr: true });

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  rows: 1,
};

function Carousel({ id, items }: { id: string; items: any[] }) {
  const { t } = useTranslation('home');
  const { t: tb } = useTranslation('resource_badges');

  return (
    <div className={styles.slider_component}>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg md:text-xl lg:text-2xl">{t(`categories.${id}`)}</h2>
        <Link href={`/category/${camelToSlug(id)}`}>
          <a className="text-lg md:text-xl lg:text-2xl">
            More <Image src="/images/svg/right-arrow.svg" alt="" height={16} width={16} />
          </a>
        </Link>
      </div>
      <Slider {...settings} className={styles.slider}>
        {items.map((item) => (
          <Card key={item.id} idx={item.id} resource={item} enabledTypes="" tb={tb} setResourceId="" />
        ))}
      </Slider>
    </div>
  );
}

export default Carousel;
